import {useNavigate} from "react-router-dom";

type PortfolioCardProps = {
    imgUrl: string;
    buttonLabel: string;
    uri: string;
}

const PortfolioCard = (props: PortfolioCardProps) => {

    const navigate = useNavigate();

    const handlePageChange = () => {

        navigate(props.uri);
    };

    return (
        <div className="flex flex-col items-center space-y-8">

            <img src={props.imgUrl} alt="Card" className="border border-[#6D493B] p-2 rounded-full object-cover w-[18rem] h-[28rem] lg:w-[23rem] lg:h-[33rem] hover:cursor-pointer hover:opacity-80" onClick={handlePageChange}/>
            <button className="border font-poppins px-6 py-4 rounded-2xl border-[#a45d45] text-[#a45d45] hover:bg-[#fdf7f1] w-[20rem] lg:w-[22rem]" onClick={handlePageChange}>
                { props.buttonLabel }
            </button>
        </div>
    );
}

export default PortfolioCard;