import React, {ReactNode} from "react";

type PortfolioDescriptionListItemProps = {
    icon: ReactNode;
    title: string;
    value: string | undefined;
};

const PortfolioDescriptionListItem = (props: PortfolioDescriptionListItemProps) => {

    return (
        <div className="flex items-center space-x-4 lg:space-x-8 xl:space-x-12">

            <div className="text-4xl lg:text-5xl xl:text-6xl text-[#827851]">{ props.icon }</div>
            <li><span className="hidden lg:inline">{ props.title }</span>{ props.value }</li>
        </div>
    );
}

export default PortfolioDescriptionListItem;