import Paragraph from "../../Paragraph/Paragraph";
import museeDouanesProjectAxonometry from "../../../assets/img/museedouanes/musee-douanes-axonometry.png";
import museeDouanesReceptionTechnicalDetail from "../../../assets/img/museedouanes/musee-douanes-reception-technical-detail.png";
import museeDouanesDisplayTechnicalDetail from "../../../assets/img/museedouanes/musee-douanes-display-technical-detail.png";
import museeDouaneProjectCut from "../../../assets/img/museedouanes/musee-douanes-project-cut.png";
import ImageWithTitle from "../../ImageWithTitle/ImageWithTitle";
import {TitlePositionEnum} from "../../ImageWithTitle/TitlePositionEnum";

const MuseeThird = () => {

    return (

        <div id="musee-third" className="min-h-screen flex flex-col bg-white bg-cover border-b-4 border-[#827851] p-6 space-y-6 items-center justify-center">

            <div className="space-y-6 xl:space-y-0 xl:flex">

                <div className="space-y-6 xl:w-1/2 xl:flex xl:flex-col xl:items-center">

                    <div className="xl:w-[85%] xl:m-6">
                        <Paragraph
                            content="Le Musée se décompose en 4 zones principales, s'ajoute à ça un espace d'exposition temporaire.
                            En créant un cheminement visuel, on plonge l'usager au cœur de l'histoire du musée, la mise en valeur d'élément fort suscite l'intérêt du visiteur et accentue sa curiosité.
                            Il est important que les éléments de support mettent en valeur les œuvres et se fondent avec l'architecture."
                        />

                        <ImageWithTitle imgUrl={museeDouanesProjectAxonometry} title="Axonométrie du projet" titlePosition={TitlePositionEnum.BottomLeft} />
                    </div>
                </div>

                <div  className="space-y-6 xl:w-1/2 xl:flex xl:flex-col xl:items-center">

                    <div className="xl:w-[85%] xl:flex xl:flex-col xl:m-6">

                        <ImageWithTitle imgUrl={museeDouanesReceptionTechnicalDetail} title="Détail technique du module d'accueil" titlePosition={TitlePositionEnum.BottomLeft} />
                        <ImageWithTitle imgUrl={museeDouanesDisplayTechnicalDetail} title="Détail technique des présentoirs de maquette" titlePosition={TitlePositionEnum.BottomLeft} />
                    </div>
                </div>
            </div>

            <div className="xl:w-[98%]">

                <ImageWithTitle imgUrl={museeDouaneProjectCut} title="Coupe du projet" titlePosition={TitlePositionEnum.BottomLeft} />
            </div>
        </div>
    );
}

export default MuseeThird;