import Paragraph from "../Paragraph/Paragraph";
import ImageWithTitle from "../ImageWithTitle/ImageWithTitle";
import {TitlePositionEnum} from "../ImageWithTitle/TitlePositionEnum";

type PortfolioTwoImagesOneTextTemplateProps = {
    paragraph: string;
    firstImage: string;
    firstImageTitle: string;
    secondImage: string;
    secondImageTitle: string;
    width?: string;
};

const PortfolioTwoImagesOneTextTemplate = (props: PortfolioTwoImagesOneTextTemplateProps) => {

    const getWidth = () => {

        if (!props.width) {

            return "40%";
        }

        return props.width;
    };

    const width = getWidth();

    const style = `flex flex-col font-poppins text-[#A45d45] p-6 space-y-4 xl:p-10 2xl:p-12 xl:w-[${width}] justify-center`;

    return (
        <div className={style}>

            <Paragraph content={ props.paragraph }/>

            <div className="flex flex-col space-y-4">

                <ImageWithTitle
                    imgUrl={ props.firstImage }
                    title={ props.firstImageTitle }
                    titlePosition={TitlePositionEnum.TopLeft}
                />

                <ImageWithTitle
                    imgUrl={ props.secondImage }
                    title={ props.secondImageTitle }
                    titlePosition={TitlePositionEnum.TopLeft}
                />
            </div>
        </div>
    );
};

export default PortfolioTwoImagesOneTextTemplate;