type ImageTitleProps = {
    title: string
};

const ImageTitle = (props : ImageTitleProps) => {

    return (

        <p className="font-poppins text-[#A45d45] italic text-sm">{ props.title }</p>
    );
}

export default ImageTitle;