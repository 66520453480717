import api from "../../configuration/Api/Api";
import {ContactForm} from "../../model/ContactForm/ContactForm";

/**
 * Service permettant de gérer l'envoi du formulaire de contact.
 */
class ContactService {

    /**
     * Méthode permettant d'envoyer un formulaire de contact.
     *
     * @param form formulaire de contact à envoyer.
     */
    async sendContactForm(form: ContactForm) {

        console.log(api.post("/email/send", form));

        return api.post("/email/send", form);
    }
}

const contractServiceInstance = new ContactService();

export default contractServiceInstance;