import Paragraph from "../../Paragraph/Paragraph";
import ImageWithTitle from "../../ImageWithTitle/ImageWithTitle";
import hoplaCoursePlanAndElevation from "../../../assets/img/hopla/hopla-course-plan-and-elevation.png";
import hoplaEnvelopRealisticVisual from "../../../assets/img/hopla/hopla-envelop-realistic-visual.png";
import hoplaCentralThreeRealisticVisual from "../../../assets/img/hopla/hopla-central-tree-realistic-visual.png";
import hoplaLevelPlan from "../../../assets/img/hopla/hopla-level-plan.png";
import hoplaTechnicalDetail from "../../../assets/img/hopla/hopla-technical-detail.png";
import {TitlePositionEnum} from "../../ImageWithTitle/TitlePositionEnum";

const HoplaThird = () => {

    return (

        <div id="hopla-third" className="min-h-screen flex flex-col bg-white border-b-4 border-[#827851] p-4 space-y-6 xl:space-y-0 xl:space-x-6 xl:flex-row">

            <div className="xl:w-1/4 xl:p-4 xl:flex xl:flex-col xl:justify-center xl:space-y-4">

                <div className="xl:hidden">
                    <ImageWithTitle imgUrl={hoplaCoursePlanAndElevation} title="Plan et élévation du parcours" titlePosition={TitlePositionEnum.BottomRight}/>
                </div>

                <div className="hidden xl:flex">
                    <ImageWithTitle imgUrl={hoplaCoursePlanAndElevation} title="Plan et élévation du parcours" titlePosition={TitlePositionEnum.BottomLeft}/>
                </div>


                <ImageWithTitle imgUrl={hoplaLevelPlan} title="Plan de niveau, Recyclerie de Bègles" titlePosition={TitlePositionEnum.BottomLeft}/>
            </div>

            <div className="xl:flex xl:flex-col xl:items-center xl:justify-center xl:w-3/4 xl:space-y-4">
                <Paragraph
                    content="La dimension éco-responsable est au cœur de ce projet.
                L’enveloppe entière du festival est créée à partir de matériaux de seconde main, auquel ont été attribué de nouveaux usages.
                Du tissu, du bois, du liège, l’ensemble des matériaux sélectionnés font écho aux mots forts de HOP LA !."
                />

                <div className="xl:w-3/4">
                    <ImageWithTitle imgUrl={hoplaTechnicalDetail} title='Detail technique "Fleurs"' titlePosition={TitlePositionEnum.BottomLeft} />
                </div>

                <div className="xl:flex xl:space-x-6">

                    <div className="xl:w-1/2">
                        <ImageWithTitle imgUrl={hoplaEnvelopRealisticVisual} title="Visuel réaliste de l'enveloppe du festival" titlePosition={TitlePositionEnum.TopLeft} />
                    </div>

                    <div className="xl:w-1/2">
                        <ImageWithTitle imgUrl={hoplaCentralThreeRealisticVisual} title="Visuel réaliste de l'arbre central" titlePosition={TitlePositionEnum.TopLeft} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HoplaThird;