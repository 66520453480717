import React from "react";
import HoplaFirst from "../../../components/FestivalHopLa/HoplaFirst/HoplaFirst";
import HoplaSecond from "../../../components/FestivalHopLa/HoplaSecond/HoplaSecond";
import HoplaThird from "../../../components/FestivalHopLa/HoplaThird/HoplaThird";
import GoToTop from "../../../components/GoToTop/GoToTop";
import GoBack from "../../../components/GoBack/GoBack";

const FestivalHopla = () => {


    return (
        <div className="relative">

            <div className="flex flex-col">
                <HoplaFirst />
                <HoplaSecond />
                <HoplaThird />
            </div>

            <GoBack />
            <GoToTop />
        </div>
    );
}

export default FestivalHopla;