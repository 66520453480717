import useScrollToTop from "../hooks/ScrollToTop/useScrollToTop";
import {Route, Routes} from "react-router-dom";
import Main from "./Main/Main";
import Mec from "./Portfolio/Mec/Mec";
import FestivalHopla from "./Portfolio/FestivalHopla/FestivalHopla";
import MuseeDouanes from "./Portfolio/MuseeDouanes/MuseeDouanes";
import ChateauChantegrive from "./Portfolio/ChateauChantegrive/ChateauChantegrive";
import NotFound from "./Errors/NotFound/NotFound";
import React from "react";

declare global {
    interface Window {
        _env_: any;
    }
}

const App = () => {

    useScrollToTop();

    return (

        <Routes>
            <Route path="/" element={ <Main /> } />
            <Route path="/maison-eco-citoyenne" element={ <Mec /> } />
            <Route path="/festival-hopla" element={ <FestivalHopla /> } />
            <Route path="/musee-des-douanes" element={ <MuseeDouanes /> } />
            <Route path="/chateau-de-chantegrive" element={ <ChateauChantegrive /> } />
            <Route path="*" element={ <NotFound /> } />
        </Routes>
    );
}

export default App;