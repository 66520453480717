import React from "react";
import PortfolioCard from "../PortfolioCard/PortfolioCard";
import mecCardImage from "../../assets/img/portfolio/mec-card.png";
import hopLaCardImage from "../../assets/img/portfolio/hopla-card.png";
import douanesCardImage from "../../assets/img/portfolio/douane-card.png";
import chantegriveCardImage from "../../assets/img/portfolio/chantegrive-card.png";

const Portfolio = () => {

    return (
        <div id="portfolio" className="2xl:h-screen flex flex-col border-b-4 bg-[#f7f6f4] border-[#827851] cursor-default">

            <h1 className="font-sego text-[#827851] text-6xl py-20 text-center 2xl:text-start 2xl:pl-20">Portfolio</h1>

            <div className="space-y-4 lg:space-y-0 pb-8 grid lg:grid-cols-2 lg:grid-rows-2 lg:gap-y-8 2xl:grid-cols-4 2xl:px-16 2xl:gap-x-6">
                <PortfolioCard imgUrl={mecCardImage} buttonLabel="La Maison Eco-Citoyenne des Landes"  uri="/maison-eco-citoyenne" />
                <PortfolioCard imgUrl={hopLaCardImage} buttonLabel="Le festival HOP LA!"  uri="/festival-hopla" />
                <PortfolioCard imgUrl={douanesCardImage} buttonLabel="Le Musée national des Douanes"  uri="/musee-des-douanes" />
                <PortfolioCard imgUrl={chantegriveCardImage} buttonLabel="Le Château de Chantegrive"  uri="/chateau-de-chantegrive" />
            </div>
        </div>
    );
};

export default Portfolio;