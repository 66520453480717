import PortfolioTitle from "../PortfolioTitle/PortfolioTitle";
import PortfolioDescriptionList from "../PortfolioDescriptionList/PortfolioDescriptionList";

type PortfolioFirstPageTemplateProps = {
    id?: string;
    title: string;
    location?: string;
    goal?: string;
    softwares?: string;
    locationImage?:string;
    background?: string;
};

const PortfolioFirstPageTemplate = (props: PortfolioFirstPageTemplateProps) => {

    const style = `min-h-screen flex flex-col ${ props.background } bg-cover bg-bottom border-b-4 border-[#827851] justify-end pb-8 p-6`;

    return (
        <div id={ props.id } className={ style }>

            <div className="flex justify-center lg:justify-normal">

                <div className="lg:w-[40%] items-center justify-center hidden lg:flex">

                    <img src={ props.locationImage } alt="Card" className="object-cover rounded-full lg:w-[15rem] lg:h-[15rem] xl:w-[20rem] xl:h-[20rem]" />
                </div>

                <div className="lg:w-[60%] flex flex-col justify-center space-y-16">

                    <div className="flex justify-center lg:justify-start">

                        <PortfolioTitle content={ props.title } />
                    </div>

                    <PortfolioDescriptionList
                        location={ props.location }
                        goal={ props.goal }
                        softwares={ props.softwares }
                    />
                </div>
            </div>
        </div>
    );
};

export default PortfolioFirstPageTemplate;