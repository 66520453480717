import React from "react";
import MuseeFirst from "../../../components/MuseeDouanes/MuseeFirst/MuseeFirst";
import MuseeSecond from "../../../components/MuseeDouanes/MuseeSecond/MuseeSecond";
import MuseeThird from "../../../components/MuseeDouanes/MuseeThird/MuseeThird";
import GoToTop from "../../../components/GoToTop/GoToTop";
import GoBack from "../../../components/GoBack/GoBack";

const MuseeDouanes = () => {

    return (
        <div className="relative">

            <div className="flex flex-col">
                <MuseeFirst />
                <MuseeSecond />
                <MuseeThird />
            </div>

            <GoBack />
            <GoToTop />
        </div>
    );
}

export default MuseeDouanes;