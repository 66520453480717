import ImageWithTitle from "../../ImageWithTitle/ImageWithTitle";
import {TitlePositionEnum} from "../../ImageWithTitle/TitlePositionEnum";
import mecLevelCut from "../../../assets/img/mec/mec-level-cut.png";
import mecLevelPlan from "../../../assets/img/mec/mec-level-plan.png";
import ImageTitle from "../../ImageTitle/ImageTitle";

const MecSecond = () => {

    return (
        <div id="mec-second" className="min-h-screen flex bg-mec-second-background bg-cover bg-right xl:bg-center border-b-4 border-[#827851]">

            <div className="flex flex-col space-y-8 p-4 xl:w-1/2 justify-center">

                <div>
                    <div className="space-y-6 xl:w-5/6">
                        <ImageWithTitle
                            imgUrl={mecLevelCut}
                            title="Coupe de niveau"
                            titlePosition={TitlePositionEnum.BottomLeft}
                        />

                        <ImageWithTitle
                            imgUrl={mecLevelPlan}
                            title="Plan de niveau"
                            titlePosition={TitlePositionEnum.BottomLeft}
                        />
                    </div>
                </div>
            </div>

            <div className="collapse xl:flex xl:visible xl:w-1/2 xl:p-6 xl:justify-end xl:items-end">

                <ImageTitle title="Visuel réaliste du café citoyen" />
            </div>
        </div>
    );
}

export default MecSecond;