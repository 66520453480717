import FormInput from "../FormInput/FormInput";
import {FormEvent, useState} from "react";
import FormTextArea from "../FormTextArea/FormTextArea";
import SendFormButton from "../SendFormButton/SendFormButton";
import ContactService from "../../service/ContactService/ContactService";

const ContactForm = () => {
    
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [content, setContent] = useState("");

    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [emailAddressValid, setEmailAddressValid] = useState(true);
    const [contentValid, setContentValid] = useState(true);

    const [showInvalidFormMessage, setShowInvalidFormMessage] = useState(false);
    const [showFailedMessage, setShowFailedMessage] = useState(false);

    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);

    /**
     * Méthode permettant de gérer l'envoi
     * du formulaire de contact.
     *
     * @param event événement concernant le formulaire
     * de contact.
     */
    const handleSendForm = (event: FormEvent) => {

        setShowFailedMessage(false);

        event.preventDefault();

        const isFormValid = validateForm();

        if (isFormValid) {

            setIsSending(true);

            ContactService.sendContactForm(
                {
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: emailAddress,
                    content: content
                }
            ).then((res) => {

                setIsSending(false);
                setIsSent(true);
                setFirstNameValid(true);
                setLastNameValid(true);
                setEmailAddressValid(true);
                setContentValid(true);
                setShowInvalidFormMessage(false);
                setShowFailedMessage(false);
            }).catch((error) => {

                setFirstNameValid(true);
                setLastNameValid(true);
                setEmailAddressValid(true);
                setContentValid(true);
                setShowInvalidFormMessage(false);
                setIsSending(false);
                setIsSent(false);
                setShowFailedMessage(true);
            });
        }
    }

    /**
     * Méthode permettant de valider le formulaire
     * de demande de contact.
     */
    const validateForm = (): boolean => {

        let isValid = true;

        if (isStringEmpty(firstName)) {

            setFirstNameValid(false);
            isValid = false;
        }

        if (isStringEmpty(lastName)) {

            setLastNameValid(false);
            isValid = false;
        }

        if (isStringEmpty(emailAddress) || !isEmailAddressValid(emailAddress)) {

            setEmailAddressValid(false);
            isValid = false;
        }

        if (isStringEmpty(content)) {

            setContentValid(false);
            isValid = false;
        }

        if (!isValid) {

            setShowInvalidFormMessage(true);
        }

        return isValid;
    }

    const isEmailAddressValid = (emailAddress: string): boolean => {

        if (!emailAddress || emailAddress === "") {

            return false;
        }

        const emailPattern = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;

        return emailPattern.test(emailAddress);
    }

    /**
     * Méthode permettant de savoir si une chaîne de
     * caractère est vide ou non.
     *
     * @param string la chaîne de caractère à vérifier.
     */
    const isStringEmpty = (string: string) => {

        return !string || string === "";
    }

    return (
        <form className="flex flex-col space-y-8 xl:space-y-14">
            <h1 className="font-sego text-[#827851] text-6xl">Me contacter</h1>

            <div className="flex justify-center">

                <div className="flex flex-col space-y-6 w-[90%] xl:w-[100%]">

                    { showFailedMessage && <p className="border-red-500 border-2 rounded-xl font-poppins p-2 bg-red-300 bg-opacity-50 text-red-500">Une erreur est survenue. Veuillez réessayer.</p> }

                    <p className="font-poppins text-xs xl:text-sm opacity-60 text-[#A45D45]">*Les champs sont obligatoires.</p>

                    <div className="flex flex-col space-y-6 xl:space-y-0 xl:flex-row xl:space-x-2">
                        <FormInput label="Nom*" value={lastName} onChange={setLastName} valid={lastNameValid} disabled={ isSending || isSent } />
                        <FormInput label="Prénom*" value={firstName} onChange={setFirstName} valid={firstNameValid} disabled={ isSending || isSent } />
                    </div>

                    <FormInput label="Adresse mail*" value={emailAddress} onChange={setEmailAddress} valid={emailAddressValid} disabled={ isSending || isSent } />
                    <FormTextArea label="Message*" value={content} onChange={setContent} valid={contentValid} disabled={ isSending || isSent } />

                    { showInvalidFormMessage && <p className="font-poppins text-red-500 text-sm max-w-[21rem] xl:max-w-none">Certains champs obligatoires ne sont pas remplis ou contiennent des erreurs.</p> }

                    <div className="flex pt-4">
                        <SendFormButton onClick={handleSendForm} sent={isSent} sending={isSending} />
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ContactForm;