import chateauChantegriveLocation from "../../../assets/img/chateauchantegrive/chateau-chantegrive-location.png";
import PortfolioFirstPageTemplate from "../../PortfolioFirstPageTemplate/PortfolioFirstPageTemplate";

const ChantegriveFirst = () => {

    return (

        <PortfolioFirstPageTemplate
            id="chateau-chantegrive-first"
            title="Le château de Chantegrive"
            location="Podensac, 33720"
            goal="Créer une maison d'hôte dans une ancienne bâtisse, proche du Château de Chantegrive, pour accueillir les clients et visiteurs"
            softwares="Archicad, Sketchup, V-ray, Photoshop"
            locationImage={ chateauChantegriveLocation }
            background="bg-chateau-chantegrive-first-background"
        />
    );
}

export default ChantegriveFirst;