type WordSequenceProps = {
    word: string;
    repetition: number;
    separator?: string;
};

const WordSequence = ({ word, repetition, separator = "-" }: WordSequenceProps) => {

    const repeatWord = (): string => {

        return Array(repetition).fill(word).join(` ${separator} `);
    }

    return (
        <div className="overflow-hidden">
            <h1 className="font-sego text-[#827851] text-2xl sm:text-3xl md:text-3.5xl 2xl:text-4xl whitespace-nowrap transform -translate-x-full">{ repeatWord() }</h1>
        </div>
    );
};

export default WordSequence;