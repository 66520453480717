import PortfolioTwoImagesOneTextTemplate from "../../PortfolioTwoImageOneTextTemplate/PortfolioTwoImagesOneTextTemplate";
import museeDouanesShopRealisticVisual from "../../../assets/img/museedouanes/musee-douanes-shop-realistic-visual.png";
import museeDouanesReceptionRealisticVisual from "../../../assets/img/museedouanes/musee-douanes-reception-realistic-visual.png";
import ImageTitle from "../../ImageTitle/ImageTitle";

const MuseeSecond = () => {

    return (

        <div id="musee-second" className="min-h-screen flex xl:bg-musee-douanes-second-background bg-right-bottom bg-cover border-b-4 border-[#827851]">

            <div className="hidden xl:flex xl:w-[60%] xl:p-6">

                <ImageTitle title="Visuel réaliste de l'accueil" />
            </div>

            <PortfolioTwoImagesOneTextTemplate
                paragraph="Le Musée National des Douanes de Bordeaux est un exemple d'architecture néoclassique du XVIIIe siècle.
                Sa façade symétrique avec colonnes corinthiennes et sculptures allégoriques, ainsi que ses intérieurs spacieux, illustrent l'élégance du style classique français.
               Face à un tel bâtiment, il est nécessaire de créer une scénographie intérieure qui le mette en valeur."
                firstImage={museeDouanesShopRealisticVisual}
                firstImageTitle="Visuel réaliste de la boutique"
                secondImage={museeDouanesReceptionRealisticVisual}
                secondImageTitle="Visuel réaliste de l'accueil"
            />
        </div>
    );
}

export default MuseeSecond;