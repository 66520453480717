type ParagraphProps = {
    content: string;
};

const Paragraph = (props: ParagraphProps) => {

    return (

        <p className="font-poppins text-[#A45d45] text-justify text-sm">
            { props.content }
        </p>
    );
}

export default Paragraph;