import React from "react";
import ChantegriveFirst from "../../../components/ChateauChantegrive/ChantegriveFirst/ChantegriveFirst";
import ChantegriveSecond from "../../../components/ChateauChantegrive/ChantegriveSecond/ChantegriveSecond";
import ChantegriveThird from "../../../components/ChateauChantegrive/ChantegriveThird/ChantegriveThird";
import ChantegriveFourth from "../../../components/ChateauChantegrive/ChantegriveFourth/ChantegriveFourth";
import GoToTop from "../../../components/GoToTop/GoToTop";
import GoBack from "../../../components/GoBack/GoBack";

const ChateauChantegrive = () => {

    return (
        <div className="relative">

            <div className="flex flex-col">
                <ChantegriveFirst />
                <ChantegriveSecond />
                <ChantegriveThird />
                <ChantegriveFourth />
            </div>

            <GoBack />
            <GoToTop />
        </div>
    );
}

export default ChateauChantegrive;