import {useLocation} from "react-router-dom";
import {useEffect} from "react";

const useScrollToTop = () => {

    const { pathname } = useLocation();

    useEffect(() => {

        window.scrollTo(0, 0);
    }, [pathname]);
};

export default useScrollToTop;