import React from 'react';
import Presentation from "../../components/Presentation/Presentation";
import About from "../../components/About/About";
import Contact from "../../components/Contact/Contact";
import Portfolio from "../../components/Portfolio/Portfolio";
import GoToTop from "../../components/GoToTop/GoToTop";

const Main = () => {

    return (
        <div className="relative">
            <div className="flex flex-col">
                <Presentation/>
                <About/>
                <Portfolio/>
                <Contact/>
            </div>

            <GoToTop />
        </div>
    );
}

export default Main;
