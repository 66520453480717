import mecRealisticVisualBridge from "../../../assets/img/mec/mec-realistic-visual-bridge.png";
import mecRealisticVisualCoffee from "../../../assets/img/mec/mec-realistic-visual-coffee.png";
import PortfolioTwoImagesOneTextTemplate from "../../PortfolioTwoImageOneTextTemplate/PortfolioTwoImagesOneTextTemplate";
import ImageTitle from "../../ImageTitle/ImageTitle";

const MecThird = () => {

    return (

        <div id="mec-third" className="min-h-screen flex xl:bg-mec-third-background bg-right-bottom bg-cover border-b-4 border-[#827851]">

            <div className="hidden xl:flex xl:w-[60%] xl:p-6">

                <ImageTitle title="Visuel réaliste du café citoyen" />
            </div>

            <PortfolioTwoImagesOneTextTemplate
                paragraph="La Maison Éco Citoyenne a pour but d'informer, d'éduquer et de partager.
                        L'aspect éco-responsable est un axe fort de ce lieu. En lui donnant une image plus actuelle et
                        mise en valeur, il incite à l'apaisement et à la découverte.
                        Chacun crée son parcours dans cet univers, en prenant le temps de découvrir un sujet d'actualité
                        dans un lieu unique et ouvert sur la nature."
                firstImage={mecRealisticVisualCoffee}
                firstImageTitle="Visuel réaliste du café citoyen"
                secondImage={mecRealisticVisualBridge}
                secondImageTitle="Visuel réaliste de la maison éco-citoyenne"
            />
        </div>
    );
}

export default MecThird;