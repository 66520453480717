import mecLocationImage from "../../../assets/img/mec/mec-location.png";
import PortfolioFirstPageTemplate from "../../PortfolioFirstPageTemplate/PortfolioFirstPageTemplate";

const MecFirst = () => {

    return (

        <PortfolioFirstPageTemplate
            id="mec-first"
            title="La Maison Éco-Citoyenne des Landes"
            location="Saint pierre du Mont, 40280"
            goal="Créer un lieu de vie qui accueille la Maison Éco-Citoyenne des Landes dans une ancienne usine de maïs"
            softwares="Archicad, Sketchup, V-ray, Photoshop"
            locationImage={ mecLocationImage }
            background="bg-mec-first-background"
        />
    );
}

export default MecFirst;