import chateauChantegriveBedroomRealisticVisual from "../../../assets/img/chateauchantegrive/chateau-chantegrive-bedroom-realistic-visual.png";
import chateauChantegriveAppartmentRealisticVisual from "../../../assets/img/chateauchantegrive/chateau-chantegrive-appartment-realistic-visual.png";
import ImageTitle from "../../ImageTitle/ImageTitle";
import PortfolioTwoImagesOneTextTemplate from "../../PortfolioTwoImageOneTextTemplate/PortfolioTwoImagesOneTextTemplate";

const ChantegriveThird = () => {

    return (

        <div id="chateau-chantegrive-third" className="min-h-screen flex xl:bg-chateau-chantegrive-third-background bg-center bg-cover border-b-4 border-[#827851]">

            <div className="xl:w-[65%] xl:flex xl:justify-center">
                <PortfolioTwoImagesOneTextTemplate
                    paragraph="Le château n'existe que depuis quelques decenies, alors pour ramener l'histoire du vin, il était crucial de mettre en valeur les années fortes qu'à connu Podensac pour le vin.
                        Chaque chambre est à l'image d'une des années, en arborant les valeurs fortes de cette dernière.
                        Désigner, couleurs, inspirations, chaque chambre est un espace à part entière, en gardant la trame du lieu."
                    firstImage={chateauChantegriveBedroomRealisticVisual}
                    firstImageTitle="Visuel réaliste d'une chambre"
                    secondImage={chateauChantegriveAppartmentRealisticVisual}
                    secondImageTitle="Visuel réaliste d'un appartement"
                    width="60%"
                />
            </div>

            <div className="hidden xl:flex xl:w-[40%] xl:p-6 xl:justify-end xl:items-end">

                <ImageTitle title="Visuel réaliste d'une chambre" />
            </div>
        </div>
    );
}

export default ChantegriveThird;