import hoplaLocationImage from "../../../assets/img/hopla/hopla-location.png";
import PortfolioFirstPageTemplate from "../../PortfolioFirstPageTemplate/PortfolioFirstPageTemplate";

const HoplaFirst = () => {

    return (

        <PortfolioFirstPageTemplate
            id="hopla-first"
            title="Le festival HOP LA !"
            location="Bordeaux, 33000"
            goal="Créer l'enveloppe d'un festival pour enfant, qui s'adapte à un lieu fermé ou ouvert"
            softwares="Archicad, Sketchup, V-ray, Photoshop"
            locationImage={ hoplaLocationImage }
            background="bg-hopla-first-background"
        />
    );
}

export default HoplaFirst;