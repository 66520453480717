import React, {ReactElement} from "react";

type LinkIconProps = {
    label: string;
    uri: string;
    icon: ReactElement
    iconSize?: string;
};

const LinkIcon = (props: LinkIconProps) => {

    return (
        <div className="flex items-center space-x-2 2xl:space-x-4 font-poppins text-[#32231f] text-lg sm:text-xl">
            {React.cloneElement(props.icon, { className: "text-[#827851] text-3xl xl:text-4xl" })}
            <a href={ props.uri } target="_blank" rel="noreferrer" className="hover:underline text-[#827851]">{ props.label }</a>
        </div>
    );
};

export default LinkIcon;