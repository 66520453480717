import {IoArrowBackCircle} from "react-icons/io5";
import React from "react";
import { useNavigate } from 'react-router-dom';

const GoBack = () => {

    const navigate = useNavigate();

    const handleGoBack = () => {

        navigate(-1);
    };

    return (
        <div className="fixed top-6 left-6 z-10 border rounded-full border-[#C67053] text-[#C67053] text-5xl xl:text-6xl opacity-25 hover:opacity-100 hover:cursor-pointer" onClick={ handleGoBack }>
            <IoArrowBackCircle />
        </div>
    );
};

export default GoBack;