type FormTextAreaProps = {
    label: string;
    value: string;
    onChange: Function;
    valid: boolean;
    disabled?: boolean;
};

const FormTextArea = (props: FormTextAreaProps) => {

    const getStyle = () => {

        let baseStyle = "bg-transparent border-b-2 focus:outline-none text-[#A45D45] placeholder:italic font-poppins placeholder:opacity-70 resize-none";

        if (props.valid) {

            return `${baseStyle} placeholder-[#A45D45] border-[#A45D45]`
        }

        return `${baseStyle} placeholder-red-500 border-red-500`;
    }

    return (
        <textarea
            placeholder={props.label}
            value={ props.value }
            onChange={(e) => props.onChange(e.target.value)}
            rows={3}
            className={ getStyle() }
            disabled={ props.disabled }
        />
    );
};

export default FormTextArea;