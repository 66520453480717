type PortfolioTitleProps = {
    content: string;
};

const PortfolioTitle = (props: PortfolioTitleProps) => {

    return (
        <h1 className="font-sego text-[#A45d45] text-4xl text-center max-w-[25rem] lg:max-w-none lg:text-left lg:text-5xl xl:text-6xl">{ props.content }</h1>
    );
}

export default PortfolioTitle;