import React, {useEffect, useState} from "react";
import {IoArrowUpCircle} from "react-icons/io5";

const GoToTop = () => {

    const [isGoTopVisible, setGoTopVisible] = useState(false);

    useEffect(() => {

        const handleGoTopVisibility = () => {

            window.scrollY > (window.innerHeight/2) ? setGoTopVisible(true) : setGoTopVisible(false);
        };

        window.addEventListener('scroll', handleGoTopVisibility);

        return () => {

            window.removeEventListener('scroll', handleGoTopVisibility)
        }
    }, []);

    const handleScrollToTop = () => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (!isGoTopVisible) {

        return null;
    }

    return (
        <div className="fixed bottom-6 right-6 z-10 border rounded-full border-[#C67053] text-[#C67053] opacity-25 hover:opacity-100 hover:cursor-pointer text-5xl xl:text-6xl" onClick={ handleScrollToTop }>
            <IoArrowUpCircle />
        </div>
    );
};

export default GoToTop;