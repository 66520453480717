import Paragraph from "../../Paragraph/Paragraph";
import ImageTitle from "../../ImageTitle/ImageTitle";
import ImageWithTitle from "../../ImageWithTitle/ImageWithTitle";
import chateauChantegrivePassagewayRealisticVisual from "../../../assets/img/chateauchantegrive/chateau-chantegrive-passageway-realistic-visual.png";
import chateauChantegriveExteriorRealisticVisualSecond from "../../../assets/img/chateauchantegrive/chateau-chantegrive-exterior-realistic-visual-second.png";
import chateauChantegriveTerraceRealisticVisual from "../../../assets/img/chateauchantegrive/chateau-chantegrive-terrace-realistic-visual.png";
import chateauChantegriveTowerCut from "../../../assets/img/chateauchantegrive/chateau-chantegrive-tower-cut.png";
import {TitlePositionEnum} from "../../ImageWithTitle/TitlePositionEnum";

const ChantegriveFourth = () => {

    return (

        <div id="chateau-chantegrive-fourth" className="min-h-screen flex flex-col xl:flex-row xl:bg-chateau-chantegrive-fourth-background justify-center bg-cover border-b-4 border-[#827851] p-6 space-y-6">

            <div className="hidden xl:w-1/2 xl:flex xl:flex-col xl:justify-end">

                <ImageTitle title="Visuel réaliste de l'extérieur" />
            </div>

            <div className="space-y-6 xl:space-y-4 xl:w-1/2 xl:p-4">
                <div className="space-y-6 xl:flex xl:space-y-0 xl:space-x-4 xl:items-center">

                    <div className="xl:w-1/2">
                        <Paragraph
                            content="Pour parfaire l'expérience de l'usager dans ce lieu unique, l'extérieur apporte une touche originale et singulière qui marque l'esprit.
                            En créant une architecture modulaire autour de la végétation, l'usager peut aller et venir au gré de son envie, incitant à la balade et à l'apaisement.
                            Il est crucial que l'extérieur soit la continuité de l'histoire retranscrite à l'intérieur du lieu.
                            L'aspect léger et doux du bâtiment se retrouve dans les formes arrondies et lumineuses de l'extérieur."
                        />
                    </div>

                    <div className="xl:w-1/2">
                        <ImageWithTitle imgUrl={chateauChantegriveTerraceRealisticVisual} title="Visuel réaliste d'une terrasse" />
                    </div>
                </div>

                <div className="space-y-6 xl:flex xl:space-y-0 xl:space-x-4">

                    <ImageWithTitle imgUrl={chateauChantegriveTowerCut} title="Coupe de la tour" titlePosition={TitlePositionEnum.BottomLeft}/>
                    <ImageWithTitle imgUrl={chateauChantegrivePassagewayRealisticVisual} title="Visuel réaliste de la coursive" titlePosition={TitlePositionEnum.BottomLeft}/>
                </div>
            </div>

            <div className="xl:hidden">
                <ImageWithTitle imgUrl={chateauChantegriveExteriorRealisticVisualSecond} title="Visuel réaliste de l'extérieur" titlePosition={TitlePositionEnum.BottomLeft}/>
            </div>
        </div>
    );
}

export default ChantegriveFourth;