import {useNavigate} from "react-router-dom";

const NotFound = () => {

    const navigate = useNavigate();

    const handleMainPageReturn = () => {

        navigate("/");
    };

    return (<div className="h-screen bg-home-background flex-col flex items-center justify-center text-[#C67053]">
        <h1 className="font-sego text-10xl pb-8 cursor-default" >404</h1>

        <div className="font-poppins text-center">
            <p className="cursor-default">La page que vous recherchez n'existe pas.</p>
            <p className="underline hover:text-[#9e5b44] hover:cursor-pointer" onClick={ handleMainPageReturn }>Revenir sur la page principale.</p>
        </div>
    </div>);
};

export default NotFound;