import React from "react";
import MecFirst from "../../../components/MaisonEcoCitoyenne/MecFirst/MecFirst";
import MecSecond from "../../../components/MaisonEcoCitoyenne/MecSecond/MecSecond";
import MecThird from "../../../components/MaisonEcoCitoyenne/MecThird/MecThird";
import GoToTop from "../../../components/GoToTop/GoToTop";
import GoBack from "../../../components/GoBack/GoBack";

const Mec = () => {

    return (
        <div className="relative">

            <div className="flex flex-col">
                <MecFirst />
                <MecSecond />
                <MecThird />
            </div>

            <GoBack />
            <GoToTop />
        </div>
    );
}

export default Mec;