const Navbar = () => {

    const handleSectionNavigation = (sectionId: string) => {

        document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className="sm:grid sm:grid-cols-3 flex flex-col space-y-8 sm:space-y-0 w-full border-y-2 border-[#827851] py-10 text-[#827851] text-2xl font-bold font-poppins">
            <ul className="flex items-center justify-center h-full list-none">
                <li className="text-center cursor-pointer">
                    <button onClick={() => handleSectionNavigation("about")} className="hover:underline">A propos</button>
                </li>
            </ul>
            <ul className="flex items-center justify-center h-full list-none">
                <li className="text-center cursor-pointer">
                    <button onClick={() => handleSectionNavigation("portfolio")} className="hover:underline">Portfolio</button>
                </li>
            </ul>
            <ul className="flex items-center justify-center h-full list-none">
                <li className="text-center cursor-pointer">
                    <button onClick={() => handleSectionNavigation("contact")} className="hover:underline">Contact</button>
                </li>
            </ul>
        </div>

    );
}

export default Navbar;