import React from "react";
import WordSequence from "../WordSequence/WordSequence";

const About = () => {

    return (
        <div id="about" className="min-h-screen flex flex-col bg-about-background-small lg:bg-about-background bg-cover border-b-4 border-[#827851]">

            <div className="flex flex-grow">

                <div className="hidden xl:flex w-1/3">

                </div>

                <div className="flex flex-col xl:w-2/3 justify-center">

                    <div className="flex flex-col px-10 xl:px-24">

                        <h1 className="font-sego text-[#827851] text-center lg:text-start text-6xl py-10">A propos</h1>

                        <div className="font-poppins text-[#A45d45] space-y-6 text-sm xl:text-base">
                            <p>
                                Avec une passion débordante pour les arts qui nourrit ma créativité au quotidien, je m'immerge dans le monde des musées et des expositions artistiques avec une curiosité insatiable. Ces expériences ont façonné mon parcours vers le domaine de l'architecture d'intérieur, où je cherche à fusionner inspiration artistique et expertise professionnelle.
                            </p>
                            <p>
                                Au fil de mes voyages, l’architecture a éveillé ma curiosité, me poussant à explorer les coulisses de ces espaces captivants. Ma quête pour comprendre l'agencement subtil et les techniques des architectes d'intérieur a pris racine, et depuis, chaque découverte enrichit mon savoir.
                            </p>
                            <p>
                                Aujourd'hui, je m'investis avec sérieux et détermination dans mon Master en Architecture d'Intérieur et Design au campus Ynov BORDEAUX. Mon objectif? Fusionner ma passion pour l'art et l'architecture permettant la création d'espaces exceptionnels qui reflètent la diversité de l’un et de l’autre.
                            </p>
                            <p>
                                Sourire aux lèvres et énergie débordante, je me définis comme une personne dynamique et ambitieuse. Ma rigueur quotidienne transparaît dans chaque projet que j'entreprends. J'aspire à apporter l'ensemble de mes compétences et à contribuer à l'épanouissement de vos projets.
                            </p>
                            <p className="font-bold">
                                Ensemble, créons des espaces exceptionnels qui transcendent les frontières de l'inspiration.
                            </p>
                        </div>

                        <div className="flex flex-row justify-center lg:justify-end pt-8">
                            <a className="border font-poppins px-4 py-2 xl:px-6 xl:py-4 rounded-xl xl:rounded-2xl border-[#a45d45] text-[#a45d45] hover:bg-[#fdf7f1] text-sm xl:text-base" href="files/cv/cv.pdf" download="cv_amandine_suavin_2024.pdf">Télécharger mon CV</a>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="flex flex-col flex-grow lg:w-4/12 xl:w-6/12 pb-10 px-6 xl:px-4 justify-center cursor-default">*/}

            {/*    <h1 className="font-sego text-[#827851] text-center lg:text-start text-6xl py-10">A propos</h1>*/}

                {/*<div className="font-poppins text-[#A45d45] space-y-6 text-sm xl:text-base">*/}
                {/*    <p>*/}
                {/*        Avec une passion débordante pour les arts qui nourrit ma créativité au quotidien, je m'immerge dans le monde des musées et des expositions artistiques avec une curiosité insatiable. Ces expériences ont façonné mon parcours vers le domaine de l'architecture d'intérieur, où je cherche à fusionner inspiration artistique et expertise professionnelle.*/}
                {/*    </p>*/}
                {/*    <p>*/}
                {/*        Au fil de mes voyages, l’architecture a éveillé ma curiosité, me poussant à explorer les coulisses de ces espaces captivants. Ma quête pour comprendre l'agencement subtil et les techniques des architectes d'intérieur a pris racine, et depuis, chaque découverte enrichit mon savoir.*/}
                {/*    </p>*/}
                {/*    <p>*/}
                {/*        Aujourd'hui, je m'investis avec sérieux et détermination dans mon Master en Architecture d'Intérieur et Design au campus Ynov BORDEAUX. Mon objectif? Fusionner ma passion pour l'art et l'architecture permettant la création d'espaces exceptionnels qui reflètent la diversité de l’un et de l’autre.*/}
                {/*    </p>*/}
                {/*    <p>*/}
                {/*        Sourire aux lèvres et énergie débordante, je me définis comme une personne dynamique et ambitieuse. Ma rigueur quotidienne transparaît dans chaque projet que j'entreprends. J'aspire à apporter l'ensemble de mes compétences et à contribuer à l'épanouissement de vos projets.*/}
                {/*    </p>*/}
                {/*    <p className="font-bold">*/}
                {/*        Ensemble, créons des espaces exceptionnels qui transcendent les frontières de l'inspiration.*/}
                {/*    </p>*/}
                {/*</div>*/}

                {/*<div className="flex flex-row justify-center lg:justify-end pt-8">*/}
                {/*    <a className="border font-poppins px-4 py-2 xl:px-6 xl:py-4 rounded-xl xl:rounded-2xl border-[#a45d45] text-[#a45d45] hover:bg-[#fdf7f1] text-sm xl:text-base" href="files/cv/cv.pdf" download="cv_amandine_suavin_2024.pdf">Télécharger mon CV</a>*/}
                {/*</div>*/}
            {/*</div>*/}

            <div className="pb-16 pt-12 cursor-default">
                <WordSequence word="Architecte d'intérieur et Designer" repetition={30} />
            </div>
        </div>
    );
};

export default About;