import ImageWithTitle from "../../ImageWithTitle/ImageWithTitle";
import chateauChantegriveReceptionRealisticVisual from "../../../assets/img/chateauchantegrive/chateau-chantegrive-reception-realistic-visual.png";
import {TitlePositionEnum} from "../../ImageWithTitle/TitlePositionEnum";
import chateauChantegriveExteriorRealisticVisual from "../../../assets/img/chateauchantegrive/chateau-chantegrive-exterior-realistic-visual.png";
import chateauChantegriveDevelopmentPlan from "../../../assets/img/chateauchantegrive/chateau-chantegrive-development-plan.png";
import chateauChantegriveNorthFacadeElevation from "../../../assets/img/chateauchantegrive/chateau-chantegrive-north-facade-elevation.png";
import chateauChantegriveSouthFacadeElevation from "../../../assets/img/chateauchantegrive/chateau-chantegrive-south-facade-elevation.png";
import PortfolioTwoImagesOneTextTemplate from "../../PortfolioTwoImageOneTextTemplate/PortfolioTwoImagesOneTextTemplate";

const ChantegriveSecond = () => {

    return (

        <div id="chateau-chantegrive-second" className="min-h-screen flex flex-col bg-cover border-b-4 border-[#827851]">

            <div className="flex flex-col xl:flex-row items-center justify-center">

                <div className="flex flex-col xl:w-[60%] items-center">

                    <div className="flex flex-col p-6 space-y-4 xl:w-[80%] xl:items-center">

                        <ImageWithTitle
                            imgUrl={chateauChantegriveDevelopmentPlan}
                            title="Plan d'aménagement"
                            titlePosition={TitlePositionEnum.BottomLeft}
                        />

                        <ImageWithTitle
                            imgUrl={chateauChantegriveNorthFacadeElevation}
                            title="Élevation de la façade Nord"
                            titlePosition={TitlePositionEnum.BottomLeft}
                        />

                        <ImageWithTitle
                            imgUrl={chateauChantegriveSouthFacadeElevation}
                            title="Élevation de la façade Sud"
                            titlePosition={TitlePositionEnum.BottomLeft}
                        />
                    </div>
                </div>

                <PortfolioTwoImagesOneTextTemplate
                    paragraph="L'annexe du Château est un lieu à part entière, laissé depuis des années, qui ne demandait qu'à être mis en valeur.
                                La maison d'hôte se compose d'une aile entière dédiées aux chambres et appartements pour les visiteurs.
                                Une autre partie du bâtiment se compose d'une salle commune et de salles de dégustations.
                                On retrouve également une salle de réception pour les événements ainsi qu'une maison pour le personnel."
                    firstImage={ chateauChantegriveReceptionRealisticVisual }
                    firstImageTitle="Visuel réaliste de l'accueil"
                    secondImage={ chateauChantegriveExteriorRealisticVisual }
                    secondImageTitle="Visuel réaliste de l'extérieur"
                />
            </div>
        </div>
    );
}

export default ChantegriveSecond;