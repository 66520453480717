import museeDouanesLocation from "../../../assets/img/museedouanes/musee-douanes-location.png";
import PortfolioFirstPageTemplate from "../../PortfolioFirstPageTemplate/PortfolioFirstPageTemplate";

const MuseeFirst = () => {

    return (
        <PortfolioFirstPageTemplate
            id="musee-first"
            title="Le Musée national des Douanes"
            location="Bordeaux, 33000"
            goal="Re-penser la scénographie du musée, en respectant les thématiques associées à l'histoire du lieu"
            softwares="Archicad, Sketchup, V-ray, Photoshop"
            locationImage={ museeDouanesLocation }
            background="bg-musee-douanes-first-background"
        />
    );
}

export default MuseeFirst;