import { PiTarget } from "react-icons/pi";
import {MdComputer, MdLocationOn} from "react-icons/md";
import React from "react";
import PortfolioDescriptionListItem from "../PortfolioDescriptionListItem/PortfolioDescriptionListItem";

type PortfolioDescriptionListProps = {
    location?: string;
    goal?: string;
    softwares?: string;
};

const PortfolioDescriptionList = (props: PortfolioDescriptionListProps) => {

    return (

        <ul className="flex flex-col font-poppins text-[#A45d45] text-sm space-y-4 text-justify lg:text-md xl:text-lg lg:space-y-8 lg:list-disc lg:list-inside lg:pr-24">

            <PortfolioDescriptionListItem icon={ <MdLocationOn /> } title="Localisation : " value={ props.location } />
            <PortfolioDescriptionListItem icon={ <PiTarget /> } title="Objectif de la demande : " value={ props.goal } />
            <PortfolioDescriptionListItem icon={ <MdComputer /> } title="Logiciels utilisés : " value={ props.softwares } />
        </ul>
    );
}

export default PortfolioDescriptionList;