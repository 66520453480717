import Paragraph from "../../Paragraph/Paragraph";
import ImageWithTitle from "../../ImageWithTitle/ImageWithTitle";
import {TitlePositionEnum} from "../../ImageWithTitle/TitlePositionEnum";
import hoplaIntentionSketch from "../../../assets/img/hopla/hopla-intention-sketch.png";
import hoplaMussonvilleLevelPlan from "../../../assets/img/hopla/hopla-mussonvile-level-plan.png";
import ImageTitle from "../../ImageTitle/ImageTitle";

const HoplaSecond = () => {

    return (

        <div id="hopla-second" className="min-h-screen flex xl:bg-hopla-second-background xl:bg-center bg-cover border-b-4 border-[#827851]">

            <div className="hidden xl:flex p-6 xl:w-[60%]">

                <ImageTitle title="Visuel réaliste d'une fleur" />
            </div>

            <div className="flex flex-col p-6 space-y-6 xl:w-[40%] justify-center">

                <div className="flex flex-col space-y-6">

                    <div className="xl:flex xl:justify-end">

                        <div className="xl:w-3/5">
                            <ImageWithTitle imgUrl={hoplaMussonvilleLevelPlan} title="Plan de niveau, Parc de Mussonville" titlePosition={TitlePositionEnum.BottomRight} />
                        </div>
                    </div>

                    <div className="xl:w-3/5">

                        <ImageWithTitle imgUrl={hoplaIntentionSketch} title="Croquis d'intention du festival HOP LA !" titlePosition={TitlePositionEnum.BottomLeft} />
                    </div>
                </div>

                <Paragraph
                    content="Le festival HOP LA ! réunit petits et grands, autour d'activités diverses et variées.
                La simplicité de cet événement crée son authenticité, il était nécessaire de lui fournir une enveloppe douce et accueillante.
                En développant le concept d'arbre généalogique, le design devient acteur du parcours de l'usager.
                Partir de la source (souche) pour s'étendre vers les abris (fleurs), l'usager voyage au gré de l'évolution du festival."
                />
            </div>
        </div>
    );
}

export default HoplaSecond;