import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import LinkIcon from "../LinkIcon/LinkIcon";
import WordSequence from "../WordSequence/WordSequence";
import bordeauxLocationImage from "../../assets/img/contact/bordeaux-location.png";
import ContactForm from "../ContactForm/ContactForm";
import {MdLocationPin} from "react-icons/md";

const Contact = () => {

    return (
        <div id="contact" className="min-h-screen flex flex-col bg-home-background bg-cover border-b-4 border-[#827851]">

            <div className="flex flex-col xl:flex-row flex-grow items-center justify-center">

                <div className="flex flex-col xl:flex-row justify-center items-center xl:space-x-32">

                    <img src={bordeauxLocationImage} alt="Localisation de la ville de Bordeaux, 33000." className="hidden xl:inline rounded-full border border-[#6D493B] p-2 w-[40%] h-auto"/>

                    <div className="flex flex-col space-y-10 pt-10 xl:pt-0">

                        <ContactForm />

                        <div className="flex justify-center">

                            <div className="flex flex-col space-y-6 xl:flex-row xl:space-x-16 xl:space-y-0">
                                <LinkIcon label="Amandine Suavin" uri="https://www.linkedin.com/in/amandine-suavin-6a07a4196/" icon={ <FaLinkedinIn /> } />
                                <LinkIcon label="@amandine_suavin" uri="https://www.instagram.com/amandine_suavin/" icon={ <IoLogoInstagram /> } />

                                <div className="xl:hidden">
                                    <LinkIcon label="Bordeaux, 33000" uri="https://www.google.fr/maps/place/Bordeaux/" icon={ <MdLocationPin /> } iconSize="3.5rem" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-8 xl:pt-0 xl:pb-16 cursor-default" >
                <WordSequence word="Contact" repetition={30} />
            </div>
        </div>
    );
};

export default Contact;