import ImageTitle from "../ImageTitle/ImageTitle";
import {TitlePositionEnum} from "./TitlePositionEnum";

type ImageWithTitleProps = {
    imgUrl: string;
    title: string;
    titlePosition?: TitlePositionEnum;
};

const ImageWithTitle = (props: ImageWithTitleProps) => {

    const getInlineTitlePosition = (): string => {

        switch (props.titlePosition) {

            case TitlePositionEnum.TopRight:
            case TitlePositionEnum.BottomRight:
                return "text-end";
            case TitlePositionEnum.TopMiddle:
            case TitlePositionEnum.BottomMiddle:
                return "text-center";
            default:
                return "text-start";
        }
    };

    const getColumnTitlePosition = (): string => {

        switch (props.titlePosition) {

            case TitlePositionEnum.BottomLeft:
            case TitlePositionEnum.BottomRight:
            case TitlePositionEnum.BottomMiddle:
                return "bottom";
            default:
                return "top";
        }
    };

    const getContent = () => {

        const inlinePosition = getInlineTitlePosition();

        const globalStyle = `flex flex-col ${inlinePosition} h-full`;

        const columnPosition = getColumnTitlePosition();

        if (columnPosition === "bottom") {

            return (
                <div className={ globalStyle }>

                    <img src={ props.imgUrl } alt={ props.title } className="h-full" />
                    <ImageTitle title={ props.title } />
                </div>
            );
        }

        return (
            <div className={ globalStyle }>

                <ImageTitle title={ props.title } />
                <img src={ props.imgUrl } alt={ props.title } className="h-full" />
            </div>
        );
    };

    return getContent();
}

export default ImageWithTitle;