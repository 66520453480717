import Navbar from "../Navbar/Navbar";
import React from "react";

const Presentation = () => {

    return (
        <div className="min-h-screen flex flex-col bg-home-background bg-cover bg-center border-b-4 border-[#827851]">
            <Navbar />
            <div className="flex-grow flex-col flex items-center justify-center text-[#C67053] cursor-default">
                <h1 className="text-7xl sm:text-8.5xl md:text-9xl font-sego -mb-4 sm:-mb-6 md:-mb-8">Amandine</h1>
                <h1 className="text-7.5xl sm:text-9xl md:text-10xl font-sego">SUAVIN</h1>
                <p className="text-xl sm:text-3xl md:text-4xl pt-4 md:pt-5 font-poppins">Architecte d'intérieur & Designer</p>
            </div>
        </div>
    );
}

export default Presentation;